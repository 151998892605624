import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DRIVER, UPLOADS_URL } from "../../config/constants/api";
import { Button, Col, Steps, Row, Card, Image, message } from "antd";
import { AiFillEnvironment } from "react-icons/ai";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../components/DashboardSidebar";
import { FaArrowLeftLong } from "react-icons/fa6";
import { extractTime } from "../../config/helpers";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  DistanceMatrixService,
} from "@react-google-maps/api";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

const { Step } = Steps;
const RideDetails = () => {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const location = useLocation();
  const [pickupLocation, setPickupLocation] = useState({
    coordinates: [0, 0],
  });
  const ride = location?.state?.ride;
  useEffect(() => {
    if (
      pickupLocation.coordinates[0] === 0 &&
      pickupLocation.coordinates[1] === 0
    ) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setPickupLocation({
          coordinates: [longitude, latitude],
        });
      });
    }
  }, []);
  const loaderOptions = useMemo(
    () => ({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40",
      libraries: ["places"],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(loaderOptions);
  const pickupCoordinates = useMemo(
    () => ({
      lat: pickupLocation.coordinates[1],
      lng: pickupLocation.coordinates[0],
    }),
    [pickupLocation]
  );
  const dropoffCoordinates = useMemo(
    () => ({
      lat: ride?.pickupLocation?.coordinates[1],
      lng: ride?.pickupLocation?.coordinates[0],
    }),
    [ride]
  );
  // to get estimated reach time
  const fetchDirectionsAndDistance = async () => {
    try {
      if (window.google && window.google.maps) {
        const distanceMatrixService =
          new window.google.maps.DistanceMatrixService();

        // Fetch Distance Matrix
        return new Promise((resolve, reject) => {
          distanceMatrixService.getDistanceMatrix(
            {
              origins: [pickupCoordinates],
              destinations: [dropoffCoordinates],
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === window.google.maps.DistanceMatrixStatus.OK) {
                const distanceData = response.rows[0]?.elements[0];
                console.log(distanceData, "distanceData");
                if (
                  distanceData &&
                  distanceData.distance &&
                  distanceData.duration
                ) {
                  const durationText = distanceData.duration.text;
                  resolve(durationText); // Return the duration text
                } else {
                  console.log("Distance data is missing in the response.");
                  message.error("Distance data is missing in the response.");
                  reject("Distance data is missing.");
                }
              } else {
                console.log(`Error fetching distance matrix: ${status}`);
                message.error(
                  `Error fetching distance matrix: ${status}. Please try again.`
                );
                reject(`Error fetching distance matrix: ${status}`);
              }
            }
          );
        });
      } else {
        console.log("Google Maps API is not loaded.");
        message.error("Google Maps API is not loaded. Please try again later.");
        throw new Error("Google Maps API is not loaded.");
      }
    } catch (error) {
      console.log("Error fetching directions or distance matrix:", error);
      message.error("Error fetching data. Please try again.");
      throw error;
    }
  };
  const isDriverAllowedAtLocation = () => {
    const distance = getDistanceInKm(pickupCoordinates, pickupLocation);
    return distance <= 50;
  };
  const getDistanceInKm = (coord1, coord2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(coord2[0] - coord1.lat);
    const dLng = deg2rad(coord2[1] - coord1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(coord1.lat)) *
        Math.cos(deg2rad(coord2[0])) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };
  const deg2rad = (deg) => deg * (Math.PI / 180);
  // Accept Ride
  const handleAccept = async () => {
    if (pickupCoordinates && dropoffCoordinates) {
      // if (!isDriverAllowedAtLocation()) {
      //   swal(
      //     "System Alert",
      //     `You are not allowed to pick up from this location: ${ride?.pickupLocation?.address}`,
      //     "warning"
      //   );
      //   return;
      // }
      try {
        const duration = await fetchDirectionsAndDistance();
        Post(
          `${DRIVER.acceptRide}${ride?._id}`,
          {
            location: pickupLocation,
            approximatedReachTime: duration,
          },
          token
        )
          .then((response) => {
            if (response?.status) {
              swal(
                "System Alert",
                `Ride Has been Accepted. ${response?.data?.passengerName} is waiting for your arrival`,
                "success"
              );
              // setRideStatus("accepted");
              navigate("/ride");
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message || err?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
      } catch (error) {
        console.error("Failed to fetch duration:", error);
      }
    }
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div
                      className="about-us-section"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="bg-paren" style={{ width: "100%" }}>
                        <Card
                          className="orderDetails"
                          style={{ padding: "20px 0" }}
                        >
                          <Row
                            style={{ width: "100%", justifyContent: "center" }}
                          >
                            <Col lg={24}>
                              <div class="arrow-box">
                                <FaArrowLeftLong
                                  className="arrow"
                                  onClick={() => navigate(-1)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]}>
                            <Col
                              lg={24}
                              md={24}
                              xs={24}
                              style={{
                                textAlign: "end",
                                marginBottom: "10px",
                              }}
                            >
                              <div>
                                <Row gutter={[16, 16]} justify={"center"}>
                                  <Col
                                    lg={24}
                                    md={24}
                                    xs={24}
                                    style={{ textAlign: "end" }}
                                  >
                                    <div className="pet-name-pic">
                                      <div>
                                        <h6 className="f-16-black">
                                          Estimated Fare: ${ride?.fare}
                                          {/* Estimated Fare: $20 */}
                                        </h6>
                                        <p className="web-p">
                                          Time: {extractTime(ride?.time)}
                                        </p>
                                        {/* <p className="web-p">
                                          Time: 12:00:00 pm
                                        </p> */}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={18}
                                    md={20}
                                    xs={24}
                                    style={{ textAlign: "center" }}
                                  >
                                    <Image
                                      src={UPLOADS_URL + ride?.profile?.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{
                                        maxWidth: "220px",
                                        maxHeight: "220px",
                                      }}
                                      preview={false}
                                    />
                                    <h5>
                                      {ride?.user?.firstName +
                                        " " +
                                        ride?.user?.lastName}
                                    </h5>
                                    {/* <h5>John Doe</h5> */}
                                  </Col>
                                  <Col lg={18} md={20} xs={24}>
                                    <Steps
                                      direction="vertical"
                                      size="small"
                                      current={0}
                                      className="mb-1"
                                      style={{ padding: "18px 0" }}
                                    >
                                      <Step
                                        title="Pickup Location"
                                        icon={<AiFillEnvironment />}
                                        // description="Abc road, 123 Street New York"
                                        description={
                                          ride?.pickupLocation?.address
                                        }
                                      />
                                      <Step
                                        title="Destination"
                                        icon={<AiFillEnvironment />}
                                        // description="4264 Nelm Street, 123 Street Chicago"
                                        description={
                                          ride?.dropoffLocation?.address
                                        }
                                      />
                                    </Steps>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      {ride?.status === "PENDING" && (
                                        <>
                                          <Button
                                            className="web-btn"
                                            onClick={handleAccept}
                                          >
                                            Accept
                                          </Button>
                                          <Button
                                            className="web-btn2"
                                            onClick={() => {
                                              navigate("/ride", {
                                                state: { index: "2" },
                                              });
                                            }}
                                          >
                                            Ignore
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RideDetails;
